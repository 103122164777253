import React from 'react'
import Header from '../components/Header'
import Contact from '../components/Contact'

export default function ContactPage() {
  return (
    <div>
        <Header title={'Contact us'} />
        <Contact />
    </div>
  )
}
